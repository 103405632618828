import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import MagicTagListRow from "./MagicTagListRow/MagicTagListRow";
import "./MagicTagList.css";

function MagicTagList(props) {
    const [magicTagList, setMagicTagList] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getMagicTagList");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setMagicTagList(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const handleRemove = (site_id) => {
        setMagicTagList(prevList => prevList.filter(item => item.site_id !== site_id));
    };

    return (
        <div className={"MagicTagList GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            {magicTagList.length === 0 ? (
                <div className="TextCenter P20">
                    <p>No magic tag to setup ✨</p>
                </div>
            ) : (
                <table className='Width100'>
                    <thead>
                        <tr className="BRed TWhite">
                            <th className='P5'>Site ID</th>
                            <th className='P5'>URL</th>
                            <th className='P5'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {magicTagList.map((row, key) => (
                            <MagicTagListRow key={key} row={row} onRemove={handleRemove} />
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default MagicTagList;