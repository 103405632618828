import "./SiteHeader.css"
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { ROOT_DOMAIN_API } from "../../Constant";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

function SiteHeader(props) {
    const [siteHeader, setSiteHeader] = useState(false);
    const [siteIDMoneybox, setSiteIDMoneybox] = useState("");
    const location = useLocation();
    const [moderatorRole, setModeratorRole] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const adm_id = localStorage.getItem("adm_id");

    useEffect(() => {
        if (isLoading) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'default';
        }
    }, [isLoading]);

    const handleMoneyboxAccess = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_moneyboxToken`, {
                params: { site_id: siteIDMoneybox },
            });

            if (response.data.status && response.data.results) {
                let url = "https://www.themoneytizer.com/new_manager/login&site_id=" + siteIDMoneybox + "&tbm_hash=" + response.data.results.tbm_hash
                window.open(url.toString(), '_blank');
            } else {
                toast.error('Failed to connect to Moneybox ', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error adding site:", error);
            toast.error('Error adding site', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIsLoading(false);
        }
    };

    const suspendUser = async (userId) => {
        if (window.confirm("Are you sure you want to suspend this user?")) {
            setIsLoading(true);
            try {
                const response = await axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_moderator/bo_suspendUser`,
                    { user_id: userId }
                );

                if (response.data.status) {
                    toast.success('User suspended successfully', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                } else {
                    toast.error('Failed to suspend user ', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setIsLoading(false);
            } catch (error) {
                console.error("Error suspending user :", error);
                toast.error('Error suspending user', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        if (!props.siteID) {
            return;
        }
        setSiteIDMoneybox(props.siteID);

        (async () => {
            try {
                let payload = {
                    params: {
                        q: props.siteID,
                    }
                }
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_siteHeader", payload);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setSiteHeader(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [props.siteID]);

    useEffect(() => {
        const fetchWorkerRole = async () => {
            try {
                const payload = {
                    params: {
                        adm_id: adm_id,
                    }
                }
                const resp = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getWorkerRole", payload);
                const { data } = resp;
                if (data.status && data.results) {
                    if (data.results.some(role => role.role_id === 11)) {
                        setModeratorRole(true);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }

        fetchWorkerRole();
    }, [adm_id, moderatorRole]);

    return (
        <div className={"SiteHeader GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <div className="FlexRow AlignCenter JustifyBetween">
                <h2 className="M0">Global Informations</h2>
                <div className="FlexRow">
                    {location.pathname !== `/moneyboxAccess/${props.siteID}` && (
                        <button className="BGreen TWhite ActionButton" onClick={handleMoneyboxAccess}>
                            <i className="fa-solid fa-piggy-bank"></i>
                        </button>
                    )}
                    {location.pathname !== `/options/${props.siteID}` && (
                        <Link className="BOrange TWhite ActionButton ML5" to={`/options/${props.siteID}`}>
                            <i className="fa-solid fa-gear"></i>
                        </Link>
                    )}
                    {location.pathname !== `/statistics/${props.siteID}` && (
                        <Link className="BBlue TWhite ActionButton ML5" to={`/statistics/${props.siteID}`}>
                            <i className="fa-solid fa-chart-simple"></i>
                        </Link>
                    )}
                    {location.pathname !== `/magicTagSetup/${props.siteID}` && (
                        <Link to={`/magicTagSetup/${props.siteID}`} className="ActionButton ML5 BBlue TWhite">
                            <i className="fa-solid fa-wand-magic-sparkles"></i>
                        </Link>
                    )}
                </div>
            </div>
            <h5 className="M0 MT5 MB5">{siteHeader.site_id ?? '/'} | {siteHeader.site_url ?? '/'}</h5>
            <div className="FlexRow AlignCenter JustifyBetween">
                <div className="FlexRow Gap10 MB10 MT10">
                    <div
                        className={"BorderRad3 BBlue P5 FlexRow AlignCenter JustifyCenter WidthFitContent WidthPx90 TWhite Height25px " +
                            (siteHeader.user_scoring ? "" : "Hide")
                        }>
                        <i className={"fa-solid fa-arrow-up-short-wide"}></i>
                        <p className='M0 LightText ML5'>Priority {siteHeader.user_scoring}</p>
                    </div>
                    <div
                        className={"BorderRad3 P5 FlexRow AlignCenter JustifyCenter WidthFitContent WidthPx90 TWhite Height25px " +
                            (siteHeader.score_total ?
                                (parseInt(siteHeader.score_total, 10) <= 5 ? "BRed" :
                                    parseInt(siteHeader.score_total, 10) <= 10 ? "BOrange" : "BGreen")
                                : "Hide")
                        }>
                        <i className={"fa-solid fa-star"}></i>
                        <p className='M0 LightText ML5'>Score {siteHeader.score_total}</p>
                    </div>
                    <div
                        className={"BorderRad3 BPixelatedGrass P5 FlexRow AlignCenter JustifyAround WidthFitContent WidthPx90 TWhite Height25px " + (siteHeader.site_revshare ? "" : "Hide")}>
                        <i className={"fa-solid fa-hand-holding-dollar"}></i>
                        <p className='M0 LightText ML5'>Rev {siteHeader.site_revshare}%</p>
                    </div>
                    <div
                        className={"BorderRad3 BOrange P5 FlexRow AlignCenter JustifyCenter WidthFitContent WidthPx90 TWhite Height25px " +
                            (siteHeader.raison_refus === "adcash" ? "" : "Hide")
                        }>
                        <i className={"fa-solid fa-poo"}></i>
                        <p className='M0 LightText ML5'>{siteHeader.raison_refus}</p>
                    </div>
                    <div
                        className={"BorderRad3 BBlack P5 FlexRow AlignCenter JustifyAround WidthFitContent TWhite Height25px " + (siteHeader.vu_similar ? "" : "Hide")}>
                        <i className={"fa-solid fa-users-viewfinder"}></i>
                        <p className='M0 LightText ML5'>{siteHeader.vu_similar} VU/month</p>
                    </div>
                    <div
                        className={"BorderRad3 BBlack P5 FlexRow AlignCenter JustifyAround WidthFitContent TWhite Height25px " + (siteHeader.user_suspended === 1 ? "" : "Hide")}>
                        <i className={"fa-solid fa-ban"}></i>
                        <p className='M0 LightText ML5'>Suspended user</p>
                    </div>
                </div>
                {moderatorRole && siteHeader.user_suspended === 0 &&
                    <div>
                        <button className="BBlack TWhite ActionButton FlexRow JustifyCenter AlignCenter"
                                onClick={() => suspendUser(siteHeader.site_user_id)}>
                            <i className="fa-solid fa-ban MR5"></i> Suspend user ?
                        </button>
                    </div>
                }
            </div>
            <table className="Width100">
                <thead>
                    <tr className="BRed TWhite">
                        <th className="P5">Site ID</th>
                        <th className="P5">User ID</th>
                        <th className="P5">Firstname - Lastname</th>
                        <th className="P5">Url</th>
                        <th className="P5">Contact Infos</th>
                        <th className="P5">Platform</th>
                        <th className="P5">Theme</th>
                        <th className="P5">Nb SSP J-2</th>
                        <th className="P5">Subscription Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="P15">{siteHeader.site_id ?? '/'}</td>
                        <td className="P15">{siteHeader.site_user_id ?? '/'}</td>
                        <td className="P15">{siteHeader.user_firstname ?? '/'} {siteHeader.user_lastname ?? '/'}</td>
                        <td className="P15">{siteHeader.site_url ?? '/'}</td>
                        <td className="P15">
                            <div>{siteHeader.user_mail ?? '/'}</div>
                            <div>{siteHeader.user_area_code_phone ? '+' + siteHeader.user_area_code_phone : ''}{siteHeader.user_phone}</div>
                        </td>
                        <td className="P15">{siteHeader.user_plateform ?? '/'}</td>
                        <td className="P15">{siteHeader.theme_name ?? '/'}</td>
                        <td className="P15">{siteHeader.sns_nb_ssp ?? '/'}</td>
                        <td className="P15">{siteHeader.site_date ?? '/'}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default SiteHeader;
